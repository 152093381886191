/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import {
  DirectDepositDocuments,
  OnboardingDocumentsReadOnly,
  taxDocumentDictionary,
} from "../../../tools";
import CustomButton from "../../../components/CustomButton";

export default function DocumentRequestElement({
    documentRequest,
    handleShowModal,
    handleDownloadDocumentRequest,
    handleDismissRequest,
  }) {
    const [isDismissing, setIsDismissing] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
  
    const documentsString = Object.keys(documentRequest.documents)
    .filter((key) => OnboardingDocumentsReadOnly[key] !== undefined)
    .map((key) => OnboardingDocumentsReadOnly[key])
    .join(', ');

    return (
      <>
        <tr
          className="cursor-pointer"
          onClick={() => handleShowModal(documentRequest)}
        >
          <td>{documentRequest.employee.companyName}</td>
          <td>{documentRequest.employee.csaName}</td>
          <td>
            {documentRequest.employee?.firstName}{" "}
            {documentRequest.employee?.lastName}
          </td>
          <td>{moment(documentRequest.dateCreated).format("MMM D, YYYY")}</td>
          <td>{documentsString}</td>
          <td>
            <div style={{ display: "flex", gap: 4 }}>
              <CustomButton
                label={"Download All"}
                style={{ padding: "0px 6px 0px 6px", whiteSpace: "nowrap" }}
                isLoading={isDownloading}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDownloadDocumentRequest(
                    documentRequest,
                    setIsDownloading
                  );
                }}
              />
              <CustomButton
                label="Dismiss"
                style={{ padding: "0px 6px 0px 6px" }}
                isLoading={isDismissing}
                onClick={(event) => {
                  event.stopPropagation();
                  setShowConfirmation(true);
                }}
              />
            </div>
          </td>
        </tr>
        <Modal
          show={showConfirmation}
          onHide={() => {
            setShowConfirmation(false);
          }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Dismiss Document Request?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            The document request will no longer be visible here, but will still be
            accessible on the Tactical Contractor's Human Resources page.
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              isLoading={isDismissing}
              label="Dismiss Document Request"
              onClick={() =>
                handleDismissRequest(
                  documentRequest,
                  setIsDismissing,
                  setShowConfirmation
                )
              }
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  