/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

export default function EDDocumentPreview({ document }) {
    const [base64, _setBase64] = useState(document.base64);
    const [url, setUrl] = useState("");
  
    useEffect(() => {
      const fetchAndSetUrl = async () => {
        if (base64) {
          try {
            const fetchResponse = await fetch(base64);
            const blob = await fetchResponse.blob();
            setUrl(window.URL.createObjectURL(blob));
          } catch (error) {
            console.error("Error fetching or creating URL:", error);
          }
        }
      };
  
      fetchAndSetUrl();
    }, [base64]);
  
    const renderDocument = () => {
      if (base64.split(",", 1)[0].includes("pdf")) {
        return (
          <div
            style={{
              backgroundColor: "#f4f4f5",
              height: "100%",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <iframe
              src={url}
              width="100%"
              height="100%"
              title="Embedded Page"
              style={{ border: "none", overflow: "hidden" }}
            />
          </div>
        );
      } else if (base64) {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              backgroundColor: "#f4f4f5",
              overflow: "hidden",
            }}
          >
            <img
              src={base64}
              alt={document.name}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
        );
      }
      return null;
    };
  
    const failedToPreviewDocument = () => {
      return (
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            padding: "16px",
            color: "var(--bs-danger)",
          }}
        >
          <FontAwesomeIcon
            icon={faXmarkCircle}
            style={{ marginRight: 8, height: 24, width: 24 }}
          />
          <span style={{ fontWeight: 500 }}>Failed to preview document</span>
        </div>
      );
    };
  
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Previewing Document</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {renderDocument() ?? failedToPreviewDocument()}
        </Modal.Body>
      </>
    );
  }