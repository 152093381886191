/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { dismissDocumentRequest, getDashboard } from '../../../services/DashboardService';
import { downloadDocumentRequest } from '../../../services/HRServices/HRDocumentsService';
import moment from 'moment';
import CustomButton from '../../../components/CustomButton';
import QuickTable from '../../../components/QuickTable';
import PageSpinner from '../../../components/PageSpinner';
import { DirectDepositDocuments, OnboardingDocumentsReadOnly, taxDocumentDictionary } from '../../../tools';
import DocumentRequestDetails from '../../HumanResources/HRDocuments/DocumentRequestDetails';
import { Dropdown, Form, InputGroup, Modal } from 'react-bootstrap';
import PDFMerger from 'pdf-merger-js/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

export default function DashboardOld() {
    const [isLoading, setIsLoading] = useState(false);
    const [requestsAwaitingAdmin, setRequestsAwaitingAdmin] = useState([]);
    const [requestsAwaitingEmployer, setRequestsAwaitingEmployer] = useState([]);
    const [requestsAwaitingEmployee, setRequestsAwaitingEmployee] = useState([]);

    const [filter, setFilter] = useState('');
    const [filterType, setFilterType] = useState('Company');

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getDashboard();
        if (response.status == '200') {
            setRequestsAwaitingAdmin(response.requestsAwaitingAdmin);
            setRequestsAwaitingEmployer(response.requestsAwaitingEmployer);
            setRequestsAwaitingEmployee(response.requestsAwaitingEmployee);
        }
        setIsLoading(false);
    }

    const handleRemoveRequest = (uid) => {
        setRequestsAwaitingAdmin(requestsAwaitingAdmin.filter(d => d.uid != uid));
        setRequestsAwaitingEmployer(requestsAwaitingEmployer.filter(d => d.uid != uid));
        setRequestsAwaitingEmployee(requestsAwaitingEmployee.filter(d => d.uid != uid));
    }

    const handleRemoveEdDocument = (documentRequestIdentifier, documentType) => {
        const newArray = 
            requestsAwaitingAdmin.find(r => r.uid == documentRequestIdentifier) ? Array.from(requestsAwaitingAdmin)
            : requestsAwaitingEmployer.find(r => r.uid == documentRequestIdentifier) ? Array.from(requestsAwaitingEmployer)
            : Array.from(requestsAwaitingEmployee)
        ;
        const request = newArray.find(r => r.uid == documentRequestIdentifier);
        if(typeof documentType === 'number'){
            request.documents.custom = request.documents.custom.filter(
                (customDoc) => customDoc.uid !== documentType
            );
        }else{
            delete request.documents[documentType];
        }

        if (requestsAwaitingAdmin.find(r => r.uid == documentRequestIdentifier)) {
            setRequestsAwaitingAdmin(newArray);
        } else if (requestsAwaitingEmployer.find(r => r.uid == documentRequestIdentifier)) {
            setRequestsAwaitingEmployer(newArray);
        } else {
            setRequestsAwaitingEmployee(newArray);
        }
    }

    const handleSetEDDocumentIncomplete = (documentRequestIdentifier, documentType) => {
        const newArray = 
            requestsAwaitingAdmin.find(r => r.uid == documentRequestIdentifier) ? Array.from(requestsAwaitingAdmin)
            : requestsAwaitingEmployer.find(r => r.uid == documentRequestIdentifier) ? Array.from(requestsAwaitingEmployer)
            : Array.from(requestsAwaitingEmployee)
        ;
        const request = newArray.find(r => r.uid == documentRequestIdentifier);

        if(typeof documentType === 'number'){
            const document = request.documents.custom.find((customDoc) => customDoc.uid === documentType)
            document.employeeCompleted = null;
        }else{
            request.documents[documentType].employeeCompleted = null;
            if('employerCompleted' in  request.documents[documentType]){
                request.documents[documentType].employerCompleted = null;
            }
        }
        if (requestsAwaitingAdmin.find(r => r.uid == documentRequestIdentifier)) {
            setRequestsAwaitingAdmin(newArray);
        } else if (requestsAwaitingEmployer.find(r => r.uid == documentRequestIdentifier)) {
            setRequestsAwaitingEmployer(newArray);
        } else {
            setRequestsAwaitingEmployee(newArray);
        }
    }

    const filterRequests = (requests) => {
        if (!filter) {
            return requests;
        } else if (filterType == 'Company') {
            return requests.filter(r => (r.employee?.companyName ?? '').toLowerCase().includes(filter.toLowerCase()));
        } else if (filterType == 'CSA/Terminal') {
            return requests.filter(r => (r.employee?.csa?.csaName ?? r.employee?.terminalName ?? '').toLowerCase().includes(filter.toLowerCase()));
        } else if (filterType == 'Employee') {
            return requests.filter(r => `${r.employee?.firstName} ${r.employee?.lastName}`.toLowerCase().includes(filter.toLowerCase()));
        }
    }

    const adminRows = filterRequests(requestsAwaitingAdmin).map((request) => {
        return (
            <DocumentRequest key={request.uid} request={request} handleRemoveRequest={handleRemoveRequest} handleRemoveEdDocument={handleRemoveEdDocument} handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete} includeDismiss/>
        )
    })

    const employerRows = filterRequests(requestsAwaitingEmployer).map((request) => {
        return (
            <DocumentRequest key={request.uid} request={request} handleRemoveRequest={handleRemoveRequest} handleRemoveEdDocument={handleRemoveEdDocument} handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete} includeDismiss/>
        )
    })

    const employeeRows = filterRequests(requestsAwaitingEmployee).map((request) => {
        return (
            <DocumentRequest key={request.uid} request={request} handleRemoveRequest={handleRemoveRequest} handleRemoveEdDocument={handleRemoveEdDocument} handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete} includeDismiss/>
        )
    })

    const filterTypeDropdownItems = ['Company', 'CSA/Terminal', 'Employee'].map((type) => {
        return (
            <Dropdown.Item key={type} onClick={() => {setFilterType(type)}} active={filterType == type}>{type}</Dropdown.Item>
        )
    })

    return (
        
        <div>
            <InputGroup style={{marginBottom: 12}}>
                <InputGroup.Text><FontAwesomeIcon icon={faMagnifyingGlass}/></InputGroup.Text>
                <Form.Control placeholder={`Filter by ${filterType}`} value={filter} onChange={(event) => {setFilter(event.target.value)}}/>
                <Dropdown>
                    <Dropdown.Toggle>{filterType}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {filterTypeDropdownItems}
                    </Dropdown.Menu>
                </Dropdown>

            </InputGroup>

            { isLoading ? <PageSpinner/> :
                <>
                    <QuickTable responsive={false} title='Completed Document Requests' headers={['Company', 'Location', 'Employee', 'Date Created', 'Documents Requested', '']} rows={adminRows} widths={[null, null, null, 1, null, 1]} noWrap size='sm'/>
                    <QuickTable responsive={false} title='Document Requests Awaiting Employer' headers={['Company', 'Location', 'Employee', 'Date Created', 'Documents Requested', '']} rows={employerRows} widths={[null, null, null, 1, null, 1]} noWrap size='sm'/>
                    <QuickTable responsive={false} title='Document Requests Awaiting Employee' headers={['Company', 'Location', 'Employee', 'Date Created', 'Documents Requested', '']} rows={employeeRows} widths={[null, null, null, 1, null, 1]} noWrap size='sm'/>
                </>
            }
        </div>
    );
}

function DocumentRequest({request, handleRemoveRequest, handleRemoveEdDocument, handleSetEDDocumentIncomplete, includeDismiss}) {
    const [isDismissing, setIsDismissing] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleDismissRequest = async () => {
        setIsDismissing(true);
        const response = await dismissDocumentRequest(request.uid);
        if (response.status == '200') {
            handleRemoveRequest(request.uid);
            setShowConfirmation(false);
        }
        setIsDismissing(false);
    }

    const handleDownloadDocumentRequest = async () => {
        setIsDownloading(request.uid);
        const response = await downloadDocumentRequest(request.uid);
        if (response.status == '200') {
            const merger = new PDFMerger();

            const sortedDocumentTypes = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), ...Object.keys(DirectDepositDocuments), 'f8850'].filter(d => Object.keys(response.documents).includes(d));

            for (let i = 0; i < sortedDocumentTypes.length; i++) {
                const document = response.documents[sortedDocumentTypes[i]];
                if (document.base64) {
                    const fetchResponse = await fetch(document.base64);
                    const blob = await fetchResponse.blob();
                    await merger.add(blob, null);
                }
            }

            for (const customDocument of response.documents.custom) {
                if (customDocument.base64) {
                    const fetchResponse = await fetch(customDocument.base64);
                    const blob = await fetchResponse.blob();
                    await merger.add(blob, null);
                }
            }
    
            const mergedPdf = await merger.saveAsBlob();
    
            const elem = window.document.createElement('a');
            elem.target = '_blank';
            elem.href = window.URL.createObjectURL(mergedPdf);
            elem.download = `${request.employee?.firstName ?? ''} ${request.employee?.lastName ?? ''} Document Request ${moment(request.dateCreated).format('MMM D, YYYY')}`;        
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
        setIsDownloading(undefined);
    }

    const includedDocuments = Object.keys(request.documents ?? {});
    let documents = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), ...Object.keys(DirectDepositDocuments), 'f8850'].filter(d => includedDocuments.includes(d)).map(d => OnboardingDocumentsReadOnly[d]);

    if(request.documents?.i9){
        documents.push(`Driver's License`)
        documents.push('Social Security Card')
    }
    if(request.documents.custom.length > 0){
        documents.push(...request.documents.custom.map((document) => document.documentInfo.name))
    }
    documents = documents.join(', ');

    return (
        <>
            <tr className='cursor-pointer' onClick={() => {setShowModal(true)}}>
                <td>{request.employee?.companyName}</td>
                <td>{request.employee?.csa?.csaName ?? request.employee?.terminalName ?? request?.employee?.locationName}</td>
                <td>{`${request.employee?.firstName} ${request.employee?.lastName}`}</td>
                <td>{moment(request.dateCreated).format('MMM D, YYYY')}</td>
                <td>{documents}</td>
                <td>
                    <div style={{display: 'flex', gap: 4}}>
                        <CustomButton label={includeDismiss ? 'Download All' : 'Download Incomplete'} style={{padding: '0px 6px 0px 6px', whiteSpace: 'nowrap'}} isLoading={isDownloading} onClick={(event) => {event.stopPropagation(); handleDownloadDocumentRequest()}}/>
                        { includeDismiss && 
                            <CustomButton label='Dismiss' style={{padding: '0px 6px 0px 6px'}} isLoading={isDismissing} onClick={(event) => {event.stopPropagation(); setShowConfirmation(true)}}/>
                        }
                    </div>
                </td>
            </tr>
            <Modal key={request.uid + ' modal 1'} show={showConfirmation} onHide={() => {setShowConfirmation(false)}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Dismiss Document Request?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    The document request will no longer be visible here, but will still be accessible on the HR Documents page.
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton isLoading={isDismissing} label='Dismiss Document Request' onClick={handleDismissRequest}/>
                </Modal.Footer>
            </Modal>
            <Modal key={request.uid + ' modal 2'} show={showModal} onHide={() => {setShowModal(false)}} size='xl'>
                <DocumentRequestDetails documentRequest={request} handleRemoveEdDocument={handleRemoveEdDocument} handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete}/>
            </Modal>
        </>

    )
}