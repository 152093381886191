/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import LoadingWrapper from "../../../components/LoadingWrapper";
import {
  Button,
  Dropdown,
  Form,
  ListGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import QuickTable from "../../../components/QuickTable";
import moment from "moment";
import {
  DirectDepositDocuments,
  downloadBase64,
  formatName,
  OnboardingDocumentsReadOnly,
  reactPdfToBase64,
  taxDocumentDictionary,
} from "../../../tools";
import CustomButton from "../../../components/CustomButton";
import PDFMerger from "pdf-merger-js";
import {
  deleteDocumentRequestDocument,
  downloadDocumentRequestDocument,
  downloadDocumentRequestOnboarding,
  previewRejectedDocument,
  rejectDocumentRequestDocument,
  sendDocumentRequestInvitationOnboarding,
} from "../../../services/HRServices/HRDocumentsService";
import {
  dismissDocumentRequestOnboarding,
  getDashboardOnboarding,
} from "../../../services/DashboardService";
import DocumentRequestElement from "./DocumentRequestElement";
import { AppGlobals } from "../../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faHistory,
  faMagnifyingGlass,
  faThumbsDown,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import KeyValueRow from "../../../components/KeyValueRow";
import AlertModal from "../../../components/AlertModals/AlertModal";
import EDDocumentPreview from "../../HumanResources/HRDocuments/EDDocumentPreview";
import { Document, Image, Page, Text } from "@react-pdf/renderer";
import OldAttachmentPreview from "../../HumanResources/HRDocuments/OldAttachmentPreview";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import TextAreaControl from "../../../components/TextAreaControl";

export default function DashboardTC2() {
  const [isLoading, setIsLoading] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDocumentRequest, setSelectedDocumentRequest] = useState(false);
  const [requestsAwaitingAdmin, setRequestsAwaitingAdmin] = useState([]);
  const [requestsAwaitingEmployer, setRequestsAwaitingEmployer] = useState([]);
  const [requestsAwaitingEmployee, setRequestsAwaitingEmployee] = useState([]);

  ///////////////////
  /// API REQUESTS
  ///////////////////

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    setIsLoading(true);
    const response = await getDashboardOnboarding();
    if (response.status == 200) {
      setRequestsAwaitingAdmin(response.requestsAwaitingAdmin);
      setRequestsAwaitingEmployer(response.requestsAwaitingEmployer);
      setRequestsAwaitingEmployee(response.requestsAwaitingEmployee);
    }

    setIsLoading(false);
  }

  async function handleDownloadDocumentRequest(
    documentRequest,
    setIsDownloading
  ) {
    setIsDownloading(documentRequest.uid);
    const response = await downloadDocumentRequestOnboarding(
      documentRequest.uid
    );
    if (response.status == 200) {
      const merger = new PDFMerger();

      const sortedDocumentTypes = [
        "offerLetter",
        "i9",
        "w4",
        "dl",
        "ssn",
        ...Object.keys(taxDocumentDictionary),
        ...Object.keys(DirectDepositDocuments),
        "f8850",
      ].filter((d) => Object.keys(response.documents).includes(d));

      for (let i = 0; i < sortedDocumentTypes.length; i++) {
        const document = response.documents[sortedDocumentTypes[i]];
        if (document?.base64) {
          if (
            sortedDocumentTypes[i] === "dl" ||
            sortedDocumentTypes[i] === "ssn"
          ) {
            const pdf = (
              <Document>
                <Page
                  size="LETTER"
                  style={{ position: "relative", padding: 50 }}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      textAlign: "center",
                      marginBottom: 12,
                    }}
                  >
                    {sortedDocumentTypes[i] === "dl"
                      ? "Driver's License"
                      : "Social Security Card"}
                  </Text>
                  <Image
                    style={{ objectFit: "contain", maxHeight: 600 }}
                    src={document.base64}
                  />
                </Page>
              </Document>
            );
            const base64 = await reactPdfToBase64(pdf);
            const fetchResponse = await fetch(base64);
            const blob = await fetchResponse.blob();
            await merger.add(blob);
          } else {
            const fetchResponse = await fetch(document.base64);
            const blob = await fetchResponse.blob();
            await merger.add(blob);
          }
        }
      }

      const mergedPdf = await merger.saveAsBlob();

      const elem = window.document.createElement("a");
      elem.target = "_blank";
      elem.href = window.URL.createObjectURL(mergedPdf);
      elem.download = `${documentRequest.employee?.firstName ?? ""} ${
        documentRequest.employee?.lastName ?? ""
      } Document Request ${moment(documentRequest.dateCreated).format(
        "MMM D, YYYY"
      )}`;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
    setIsDownloading(undefined);
  }

  async function handleDismissRequest(
    documentRequest,
    setIsDismissing,
    setShowConfirmation
  ) {
    setIsDismissing(true);
    const response = await dismissDocumentRequestOnboarding(
      documentRequest.uid
    );
    if (response.status == 200) {
      handleRemoveRequest(documentRequest.uid);
      setShowConfirmation(false);
    }
    setIsDismissing(false);
  }

  /////////////////////
  /// HANDLERS
  /////////////////////

  function handleRemoveRequest(documentRequestIdentifier) {
    setRequestsAwaitingAdmin(
      requestsAwaitingAdmin.filter((d) => d.uid != documentRequestIdentifier)
    );
    setRequestsAwaitingEmployer(
      requestsAwaitingEmployer.filter((d) => d.uid != documentRequestIdentifier)
    );
    setRequestsAwaitingEmployee(
      requestsAwaitingEmployee.filter((d) => d.uid != documentRequestIdentifier)
    );
  }

  function handleRemoveDocumentRequestDocument(
    documentRequestIdentifier,
    documentType
  ) {
    const targetArray = requestsAwaitingAdmin.find(
      (r) => r.uid === documentRequestIdentifier
    )
      ? requestsAwaitingAdmin
      : requestsAwaitingEmployer.find(
          (r) => r.uid === documentRequestIdentifier
        )
      ? requestsAwaitingEmployer
      : requestsAwaitingEmployee;

    const newArray = Array.from(targetArray);
    const request = newArray.find((r) => r.uid === documentRequestIdentifier);

    if (request) {
      delete request.documents[documentType];
    }

    if (targetArray === requestsAwaitingAdmin) {
      setRequestsAwaitingAdmin(newArray);
      setSelectedDocumentRequest(request);
    } else if (targetArray === requestsAwaitingEmployer) {
      setRequestsAwaitingEmployer(newArray);
      setSelectedDocumentRequest(request);
    } else {
      setRequestsAwaitingEmployee(newArray);
      setSelectedDocumentRequest(request);
    }
  }

  function handleSetDocumentRequestDocumentIncomplete(
    documentRequestIdentifier,
    documentType
  ) {
    const targetArray = requestsAwaitingAdmin.find(
      (r) => r.uid === documentRequestIdentifier
    )
      ? requestsAwaitingAdmin
      : requestsAwaitingEmployer.find(
          (r) => r.uid === documentRequestIdentifier
        )
      ? requestsAwaitingEmployer
      : requestsAwaitingEmployee;

    const newArray = Array.from(targetArray);
    const request = newArray.find((r) => r.uid === documentRequestIdentifier);

    const document = request?.documents[documentType];
    if (document) {
      document.employeeCompleted = null;
      if ("employerCompleted" in document) {
        document.employerCompleted = null;
      }
    }

    if (targetArray === requestsAwaitingAdmin) {
      setRequestsAwaitingAdmin(newArray);
      setSelectedDocumentRequest(request);
    } else if (targetArray === requestsAwaitingEmployer) {
      setRequestsAwaitingEmployer(newArray);
      setSelectedDocumentRequest(request);
    } else {
      setRequestsAwaitingEmployee(newArray);
      setSelectedDocumentRequest(request);
    }
  }

  function handleShowModal(documentRequest) {
    setSelectedDocumentRequest(documentRequest);
    setShowDetailsModal(true);
  }

  const adminRows = requestsAwaitingAdmin.map((documentRequest) => {
    return (
      <DocumentRequestElement
        key={documentRequest.uid}
        documentRequest={documentRequest}
        handleShowModal={handleShowModal}
        handleDownloadDocumentRequest={handleDownloadDocumentRequest}
        handleDismissRequest={handleDismissRequest}
      />
    );
  });

  const employerRows = requestsAwaitingEmployer.map((documentRequest) => {
    return (
      <DocumentRequestElement
        key={documentRequest.uid}
        documentRequest={documentRequest}
        handleShowModal={handleShowModal}
        handleDownloadDocumentRequest={handleDownloadDocumentRequest}
        handleDismissRequest={handleDismissRequest}
      />
    );
  });

  const employeeRows = requestsAwaitingEmployee.map((documentRequest) => {
    return (
      <DocumentRequestElement
        key={documentRequest.uid}
        documentRequest={documentRequest}
        handleShowModal={handleShowModal}
        handleDownloadDocumentRequest={handleDownloadDocumentRequest}
        handleDismissRequest={handleDismissRequest}
      />
    );
  });

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
        <QuickTable
          title="Completed Document Requests"
          headers={[
            "Company",
            "CSA",
            "Employee",
            "Date Created",
            "Documents Requested",
            "",
          ]}
          rows={adminRows}
          widths={[null, null, null, 1, null, 1]}
          noWrap
          size="sm"
        />
        <QuickTable
          title="Document Requests Awaiting Employer"
          headers={[
            "Company",
            "CSA",
            "Employee",
            "Date Created",
            "Documents Requested",
            "",
          ]}
          rows={employerRows}
          widths={[null, null, null, 1, null, 1]}
          noWrap
          size="sm"
        />
        <QuickTable
          title="Document Requests Awaiting Employee"
          headers={[
            "Company",
            "CSA",
            "Employee",
            "Date Created",
            "Documents Requested",
            "",
          ]}
          rows={employeeRows}
          widths={[null, null, null, 1, null, 1]}
          noWrap
          size="sm"
        />
      </div>
      <Modal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        centered
        size="xl"
      >
        <DocumentRequestDetailsOnboarding
          documentRequest={selectedDocumentRequest}
          handleRemoveDocumentRequestDocument={
            handleRemoveDocumentRequestDocument
          }
          handleSetDocumentRequestDocumentIncomplete={
            handleSetDocumentRequestDocumentIncomplete
          }
        />
      </Modal>
    </LoadingWrapper>
  );
}

function DocumentRequestDetailsOnboarding({
  documentRequest,
  handleRemoveDocumentRequestDocument,
  handleSetDocumentRequestDocumentIncomplete,
}) {
  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const [revisionMessage, setRevisionMessage] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  async function handleSendInvitation(sendTo) {
    setIsSendingInvitation(true);
    const response = await sendDocumentRequestInvitationOnboarding(
      documentRequest.uid,
      sendTo
    );
    if (response.status == 200) {
      AppGlobals.alert("success", "Sent Document Request Invitation");
    } else {
      AppGlobals.alert("danger", response.message);
    }
    setIsSendingInvitation(false);
  }

  async function handleRejectDocument() {
    setIsRejecting(true);
    const response = await rejectDocumentRequestDocument(
      documentRequest.uid,
      selectedDocument.documentType,
      revisionMessage
    );
    if (response.status == 200) {
      handleSetDocumentRequestDocumentIncomplete(
        documentRequest.uid,
        selectedDocument.documentType
      );
      setRevisionMessage("");
      setShowRejectModal(false);
    }
    setIsRejecting(false);
  }

  async function handleDownloadDocument(document, setIsDownloading) {
    setIsDownloading(true);
    if (document.base64) {
      downloadBase64(
        document.base64,
        `${documentRequest.employee?.firstName} ${
          documentRequest.employee?.lastName
        } ${OnboardingDocumentsReadOnly[document.documentType]}`
      );
    } else {
      const response = await downloadDocumentRequestDocument(
        document.uid,
        document.documentType
      );
      if (response.status == 200) {
        document.base64 = response.base64;
        downloadBase64(
          response.base64,
          `${documentRequest.employee?.firstName} ${
            documentRequest.employee?.lastName
          } ${OnboardingDocumentsReadOnly[document.documentType]}`
        );
      }
    }
    setIsDownloading(false);
  }

  async function handlePreviewDocument(document, setIsPreviewing) {
    setIsPreviewing(true);
    if (document.base64) {
      handlePreviewPdfOrOldAttachment(document);
    } else {
      const response = await downloadDocumentRequestDocument(
        document.uid,
        document.documentType
      );
      if (response.status == 200) {
        document.base64 = response.base64;
        handlePreviewPdfOrOldAttachment(document);
      }
    }
    setIsPreviewing(false);
  }

  function handlePreviewPdfOrOldAttachment(document) {
    setSelectedDocument(document);
    if (document.documentType === "ssn" || document.documentType === "dl") {
      setShowImageModal(true);
    } else {
      setShowPreviewModal(true);
    }
  }

  function handleShowRejectModal(document) {
    setSelectedDocument(document);
    setShowRejectModal(true);
  }

  const sortedDocuments = Object.entries(documentRequest.documents).filter(
    ([key, _]) => OnboardingDocumentsReadOnly[key] !== undefined
  );

  const documentRequestDocumentELements = sortedDocuments.map(
    ([_, document]) => {
      return (
        <DocumentRequestDocumentElement
          key={document.documentType}
          document={document}
          documentRequest={documentRequest}
          handlePreviewDocument={handlePreviewDocument}
          handleDownloadDocument={handleDownloadDocument}
          handleShowRejectModal={handleShowRejectModal}
          handleRemoveDocumentRequestDocument={
            handleRemoveDocumentRequestDocument
          }
        />
      );
    }
  );

  const activityElements = documentRequest.activity.map((item) => {
    return (
      <KeyValueRow
        isListItem
        key={item.uid}
        title={item.isEmployer ? "Employer" : "Employee"}
        value={moment(item.dateTime).format("MMM D, YYYY [at] h:mm A")}
      />
    );
  });

  let employerSignerMiddleName;
  let employerName;
  if (documentRequest.employerSigner) {
    employerSignerMiddleName = documentRequest.employerSigner.middleName
      ? ` ${documentRequest.employerSigner?.middleName}`
      : "";
    employerName = `${documentRequest.employerSigner.firstName}${employerSignerMiddleName} ${documentRequest.employerSigner.lastName}`;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>Document Request</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {documentRequest.employerSigner ? (
            <h5>
              <b>Employer Signer: </b>
              {employerName}
            </h5>
          ) : (
            <div></div>
          )}
          <Dropdown style={{ alignSelf: "flex-end", marginBottom: 12 }}>
            <Dropdown.Toggle disabled={isSendingInvitation}>
              {isSendingInvitation ? (
                <Spinner size="sm" />
              ) : (
                <span>Resend Invitation</span>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  handleSendInvitation("employee");
                }}
              >
                Send To Employee
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  handleSendInvitation("employer");
                }}
              >
                Send To Employer
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {documentRequestDocumentELements.length > 0 && (
          <QuickTable
            title="Documents"
            headers={[
              "Document Type",
              "Employee Completed",
              "Employer Completed",
              "Actions",
            ]}
            rows={documentRequestDocumentELements}
            widths={[null, null, null, 1]}
            size="sm"
          />
        )}
        <h5>Request View Activity</h5>
        <ListGroup>
          {activityElements.length > 0 ? (
            activityElements
          ) : (
            <ListGroup.Item>None</ListGroup.Item>
          )}
        </ListGroup>
      </Modal.Body>
      <Modal
        show={showRejectModal}
        onHide={() => {
          setShowRejectModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Document Back to Employee to Revise?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Include a message below for the employee with instructions on what
            needs revised.
          </span>
          <Form.Control
            value={revisionMessage}
            maxLength={500}
            onChange={(event) => {
              setRevisionMessage(event.target.value);
            }}
            as="textarea"
            rows={5}
          />
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            label="Request Revision"
            disabled={!revisionMessage}
            isLoading={isRejecting}
            onClick={handleRejectDocument}
          />
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPreviewModal}
        onHide={() => {
          setShowPreviewModal(false);
        }}
        fullscreen
      >
        <EDDocumentPreview document={selectedDocument} />
      </Modal>
      <Modal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        fullscreen
      >
        <OldAttachmentPreview document={selectedDocument} />
      </Modal>
    </>
  );
}

function DocumentRequestDocumentElement({
  document,
  documentRequest,
  handlePreviewDocument,
  handleDownloadDocument,
  handleShowRejectModal,
  handleRemoveDocumentRequestDocument,
}) {
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRejectedVersionsModal, setShowRejectedVersionsModal] =
    useState(false);
  const [selectedRejectedDocuments, setSelectedRejectedDocuments] = useState(
    []
  );

  async function handleDeleteDocumentRequestDocument() {
    const response = await deleteDocumentRequestDocument(
      document.uid,
      document.documentType
    );
    if (response.status == 200) {
      handleRemoveDocumentRequestDocument(
        document.documentRequestIdentifier,
        document.documentType
      );
    }
  }

  const employerCompletedCellContent =
    document?.employerCompleted !== undefined
      ? document.employerCompleted
        ? moment(document.employerCompleted).format("MMM D, YYYY")
        : "Awaiting Completion"
      : "";

  return (
    <>
      <tr>
        <td>{OnboardingDocumentsReadOnly[document.documentType]}</td>
        <td style={{ color: !document.employeeCompleted ? "red" : undefined }}>
          {document.employeeCompleted
            ? moment(document.employeeCompleted).format("MMM D, YYYY")
            : "Awaiting Completion"}
        </td>
        <td
          style={{
            color:
              "employerCompleted" in document && !document.employerCompleted
                ? "red"
                : undefined,
          }}
        >
          {employerCompletedCellContent}
        </td>
        <td>
          <div style={{ display: "flex", gap: 6, justifyContent: "flex-end" }}>
            {document.employeeCompleted ? (
              <>
                <CustomButton
                  title="Preview"
                  style={{ padding: "0px 6px" }}
                  label={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                  isLoading={isPreviewing}
                  onClick={() =>
                    handlePreviewDocument(document, setIsPreviewing)
                  }
                />
                <CustomButton
                  title="Download"
                  style={{ padding: "0px 6px" }}
                  label={<FontAwesomeIcon icon={faDownload} />}
                  isLoading={isDownloading}
                  onClick={() =>
                    handleDownloadDocument(document, setIsDownloading)
                  }
                />
                <CustomButton
                  title="Rejection History"
                  style={{ padding: "0px 6px" }}
                  disabled={
                    !documentRequest.rejectedDocuments[document.documentType]
                  }
                  label={<FontAwesomeIcon icon={faHistory} />}
                  onClick={() => {
                    setSelectedRejectedDocuments(
                      documentRequest.rejectedDocuments[document.documentType]
                    );
                    setShowRejectedVersionsModal(true);
                  }}
                />
                <Button
                  variant="danger"
                  title="Request Revision"
                  style={{ padding: "0px 6px", whiteSpace: "nowrap" }}
                  onClick={() => handleShowRejectModal(document)}
                >
                  <FontAwesomeIcon icon={faThumbsDown} />
                </Button>
              </>
            ) : (
              documentRequest?.rejectedDocuments?.[document.documentType]
                ?.length && (
                <CustomButton
                  title="Rejection History"
                  style={{ padding: "0px 6px" }}
                  disabled={
                    !documentRequest.rejectedDocuments[document.documentType]
                  }
                  label={<FontAwesomeIcon icon={faHistory} />}
                  onClick={() => {
                    setSelectedRejectedDocuments(
                      documentRequest.rejectedDocuments[document.documentType]
                    );
                    setShowRejectedVersionsModal(true);
                  }}
                />
              )
            )}
            <Button
              title="Delete"
              variant="danger"
              style={{ padding: "0px 6px" }}
              onClick={() => setShowDeleteModal(true)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </div>
        </td>
      </tr>
      <AlertModal
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        title="Permanently Delete Document"
        message="This cannot be undone."
        buttonLabel="Delete Document"
        callBack={handleDeleteDocumentRequestDocument}
      />
      <Modal
        centered
        show={showRejectedVersionsModal}
        onHide={() => setShowRejectedVersionsModal(false)}
      >
        <RejectedDocumentsModal rejectedDocuments={selectedRejectedDocuments} />
      </Modal>
    </>
  );
}

function RejectedDocumentsModal({ rejectedDocuments }) {
  const documentType = rejectedDocuments[0].documentType;
  const [document, setDocument] = useState();
  const [isPreviewing, setIsPreviewing] = useState();

  async function previewDocument(rejectedDocument) {
    setIsPreviewing(rejectedDocument.uid);
    const response = await previewRejectedDocument(
      documentType,
      rejectedDocument.uid
    );
    if (response.status == 200) {
      response.documentRequestDocument.documentRejection =
        rejectedDocument.documentRejection;
      response.documentRequestDocument.documentType = documentType;
      if (response.documentRequestDocument.employerSubmission) {
        response.documentRequestDocument.base64 =
          response.documentRequestDocument.employerSubmission;
      } else if (response.documentRequestDocument.employeeSubmission) {
        response.documentRequestDocument.base64 =
          response.documentRequestDocument.employeeSubmission;
      }
      setDocument(response.documentRequestDocument);
    } else {
      AppGlobals.alert("danger", response.message);
    }
    setIsPreviewing(undefined);
  }

  const rows = rejectedDocuments.map((rejectedDocument) => {
    return (
      <tr key={rejectedDocument.uid}>
        <td>
          {moment(rejectedDocument.documentRejection.author.dateTime).format(
            "lll"
          )}
        </td>
        <td>{formatName(rejectedDocument.documentRejection.author.user, 2)}</td>
        <td>
          <CustomButton
            title="Preview"
            style={{ padding: "0px 6px 0px 6px" }}
            label={<FontAwesomeIcon icon={faMagnifyingGlass} />}
            isLoading={isPreviewing === rejectedDocument.uid}
            onClick={() => previewDocument(rejectedDocument)}
          />
        </td>
      </tr>
    );
  });

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          Rejected Versions for {OnboardingDocumentsReadOnly[documentType]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <QuickTable
          headers={["Date Rejected", "Rejected By", "View"]}
          rows={rows}
          widths={[null, null, null]}
          size="sm"
        />
      </Modal.Body>
      <Modal
        fullscreen
        show={Boolean(document)}
        onHide={() => setDocument(undefined)}
      >
        {Boolean(document) && (
          <RejectedDocumentPreview rejectedDocument={document} />
        )}
      </Modal>
    </>
  );
}

function RejectedDocumentPreview({ rejectedDocument }) {
  const [base64, _setBase64] = useState(rejectedDocument.base64);
  const [url, setUrl] = useState("");

  useEffect(() => {
    const fetchAndSetUrl = async () => {
      if (base64) {
        try {
          const fetchResponse = await fetch(base64);
          const blob = await fetchResponse.blob();
          setUrl(window.URL.createObjectURL(blob));
        } catch (error) {
          console.error("Error fetching or creating URL:", error);
        }
      }
    };

    fetchAndSetUrl();
  }, [base64]);

  const renderDocument = () => {
    if (base64.split(",", 1)[0].includes("pdf")) {
      return (
        <div
          style={{
            backgroundColor: "#f4f4f5",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <iframe
            src={url}
            width="100%"
            height="100%"
            title="Embedded Page"
            style={{ border: "none", overflow: "hidden" }}
          />
        </div>
      );
    }else if (base64) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            backgroundColor: "#f4f4f5",
            overflow: "hidden",
          }}
        >
          <img
            src={base64}
            alt={document.name}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      );
    }
    return null;
  };

  const failedToPreviewDocument = () => {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          padding: "16px",
          color: "var(--bs-danger)",
        }}
      >
        <FontAwesomeIcon
          icon={faXmarkCircle}
          style={{ marginRight: 8, height: 24, width: 24 }}
        />
        <span style={{ fontWeight: 500 }}>Failed to preview document</span>
      </div>
    );
  };

  return (
    <>
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Previewing Rejected Document</Modal.Title>
          <p style={{ margin: 0, fontSize: "0.9rem", opacity: .7 }}>
            {`${
              OnboardingDocumentsReadOnly[rejectedDocument.documentType]
            } - Rejected by ${formatName(
              rejectedDocument.documentRejection.author.user,
              2
            )} - ${moment(
              rejectedDocument.documentRejection.author.dateTime
            ).format("lll")}`}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: 8 }}>
          <TextAreaControl
            rows={5}
            label={"Revision Message"}
            value={rejectedDocument.documentRejection.rejectionMessage}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "100%",
          }}
        >
          {renderDocument() ?? failedToPreviewDocument()}
        </div>
      </Modal.Body>
    </>
  );
}
